import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Assignment, AssignmentTurnedIn, Forum, Article, List, ContactMail, Login, AppRegistration, Upload, HowToReg, Logout, AccountCircle } from '@mui/icons-material';
import Sidebar from '@sanedi.epc/components/layout/sidebar';
import LoaderSuspense from '@sanedi.epc/components/common/loader-suspense';
import AuthValidation from '@sanedi.epc/components/common/auth-validation';
import ToolbarHeading from '@sanedi.epc/components/common/toolbar-heading';
import AuthContext from '@sanedi.epc/components/common/auth-context';
import AuthorizePage from '@sanedi.epc/pages/auth/authorize';
import LogoutPage from '@sanedi.epc/pages/auth/logout';
import withQueryClientProvider from '@sanedi.epc/components/form/with-query-client-provider';
import HomePageContainer from './components/home-page-container';

import { SnackbarProvider } from 'notistack';
import CustomAlert from '@sanedi.epc/components/common/alert';
import Role from '@sanedi.epc/types/enums/role';

const EnergyInfoNotesModal = React.lazy(() => import('@sanedi.epc/pages/energy-information/energy-info-notes'));
const EnergyInfoDocsModal = React.lazy(() => import('@sanedi.epc/pages/energy-information/energy-info-docs'));
const ViewEpcCertificatePage = React.lazy(() => import('@sanedi.epc/pages/energy-information/view-epc-certificate'));
const BuildingPage = React.lazy(() => import('@sanedi.epc/pages/building/building-page'));
const ListBuildingsPage = React.lazy(() => import('@sanedi.epc/pages/building/list-buildings'));
const EnergyInformationPage = React.lazy(() => import('@sanedi.epc/pages/energy-information/energy-information-page'));
const Error404 = React.lazy(() => import('@sanedi.epc/pages/error404'));
const DuplicateBuildingsModal = React.lazy(() => import('@sanedi.epc/pages/building/duplicate-buildings-modal'));
const HistoricalEnergyInfoModal = React.lazy(() => import('@sanedi.epc/pages/energy-information/historical-energy-info-modal'));
const ChatPopup = React.lazy(() => import('@sanedi.epc/components/specialized/chat-popup'));
const RequestPasswordResetPage = React.lazy(() => import('./pages/request-reset'));
const ResetPasswordPage = React.lazy(() => import('./pages/reset-password'));
const RegisterPage = React.lazy(() => import('./pages/register'));
const LoginPage = React.lazy(() => import('./pages/login'));
const ProfilePage = React.lazy(() => import('./pages/profile'));
const FaqView = React.lazy(() => import('./pages/faq-view'));
const Articles = React.lazy(() => import('./pages/articles'));
const VerifyEmailPage = React.lazy(() => import('./pages/verify-email'));
const SanasBodyList = React.lazy(() => import('./pages/sanas-body-list'));
const EnquiryPage = React.lazy(() => import('./pages/enquiry-page'));
const BuildingBulkUpload = React.lazy(() => import('./pages/client-bulk-upload'));
const AboutUsPage = React.lazy(() => import('./pages/about-us'));
const TeamPage = React.lazy(() => import('./pages/team'));
const GalleryPage = React.lazy(() => import('./pages/gallery'));
const LegislationPage = React.lazy(() => import('./pages/legislation'));
const InfoPage = React.lazy(() => import('./pages/info'));
const HomePage = React.lazy(() => import('./pages/home'));
const RegisteredProfessionalApplicationPage = React.lazy(() => import('./pages/registered-professionals-application'));

const QuestionOnePage = React.lazy(() => import('./pages/questions/question-1-epc-process'));
const QuestionTwoPage = React.lazy(() => import('./pages/questions/question-2-building-registration-requirements'));
const QuestionThreePage = React.lazy(() => import('./pages/questions/question-3-registration-process'));
const QuestionFourPage = React.lazy(() => import('./pages/questions/question-4-epc-requirements'));

const alertComponents = {
  success: CustomAlert,
  error: CustomAlert,
  info: CustomAlert,
  warning: CustomAlert
};

const SidebarContainer = React.memo(() => {
  return (
    <Sidebar
      heading={<ToolbarHeading>National Building Energy Performance Register</ToolbarHeading>}
      entries={[
        {
          icon: <HowToReg />,
          label: 'Professional Application',
          to: '/client/professional-application',
          hideIfPredicate: (token) => !token || (token.role !== Role.RegisteredProfessional && token.role !== Role.AccreditedBody)
        },
        {
          icon: <Assignment />,
          label: 'Register Building',
          to: '/client/register-building',
          hideIfNotAuthed: true
        },
        {
          icon: <AssignmentTurnedIn />,
          label: 'List Buildings',
          to: '/client/list-buildings',
          hideIfNotAuthed: true
        },
        {
          icon: <Upload />,
          label: 'Bulk Upload',
          to: '/client/bulk-upload',
          hideIfNotAuthed: true
        },
        { divider: true },
        {
          icon: <Forum />,
          label: 'FAQ',
          to: '/faq'

        },
        {
          icon: <Article />,
          label: 'Articles',
          to: '/articles'
        },
        {
          icon: <List />,
          label: 'Accredited Bodies',
          to: '/sanas-bodies'
        },
        {
          icon: <ContactMail />,
          label: 'Contact Us',
          to: '/enquiry'
        },
        // { divider: true },

        // {
        //   icon: <ForwardToInbox />,
        //   label: 'Invite User',
        //   to: '/client/user-invite'
        // },

        {
          icon: <AccountCircle />,
          label: 'Profile',
          to: '/client/profile',
          alignBottom: true
        },
        {
          icon: <Login />,
          label: 'Login',
          to: '/login',
          alignBottom: true,
          hideIfAuthed: process.env.NODE_ENV === 'production'
        },
        {
          icon: <AppRegistration />,
          label: 'Register',
          to: '/register',
          alignBottom: true,
          hideIfAuthed: process.env.NODE_ENV === 'production'
        },
        {
          icon: <Logout />,
          label: 'Log Out',
          to: '/logout',
          alignBottom: true
        }
      ]}
    >
      <Outlet />
    </Sidebar>
  )
});

function App() {
  const authValue = React.useState(localStorage.getItem('token'));
  return (
    <AuthContext.Provider value={authValue}>
      <SnackbarProvider
        Components={alertComponents}
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path='/client' element={<SidebarContainer />}>
            <Route path="register-building" element={<LoaderSuspense><AuthValidation><BuildingPage key='register' /></AuthValidation></LoaderSuspense>} />
            <Route path='edit-building/:buildingId' element={<LoaderSuspense><AuthValidation><BuildingPage key='edit' /></AuthValidation></LoaderSuspense>} />
            <Route path='clone-building/:clonedBuildingId' element={<LoaderSuspense><BuildingPage key='clone' /></LoaderSuspense>} />
            <Route path='view-epc-certificate/:energyInformationId' element={<LoaderSuspense><AuthValidation><ViewEpcCertificatePage /></AuthValidation></LoaderSuspense>} />
            <Route path='submit-energy-info/:buildingId' element={<LoaderSuspense><AuthValidation><EnergyInformationPage /></AuthValidation></LoaderSuspense>} />
            <Route path='edit-energy-info/:energyInfoId' element={<LoaderSuspense><AuthValidation><EnergyInformationPage /></AuthValidation></LoaderSuspense>} />
            <Route path='amend-energy-info/:energyInfoId' element={<LoaderSuspense><EnergyInformationPage isAmendment /></LoaderSuspense>} />
            <Route path='list-buildings' element={<LoaderSuspense outlet><AuthValidation><ListBuildingsPage /></AuthValidation></LoaderSuspense>}>
              <Route path='energy-info-notes/:energyInfoId' element={<LoaderSuspense modal><EnergyInfoNotesModal /></LoaderSuspense>} />
              <Route path='energy-info-docs/:buildingId/:energyInfoId' element={<LoaderSuspense modal><EnergyInfoDocsModal /></LoaderSuspense>} />
              <Route path='energy-info-docs/:buildingId' element={<LoaderSuspense modal><EnergyInfoDocsModal /></LoaderSuspense>} />
              <Route path='view-duplicates/:buildingId' element={<LoaderSuspense modal><DuplicateBuildingsModal /></LoaderSuspense>} />
              <Route path='view-history/:buildingId' element={<LoaderSuspense modal><HistoricalEnergyInfoModal /></LoaderSuspense>} />
            </Route>
            <Route path='professional-application' element={<LoaderSuspense><AuthValidation><RegisteredProfessionalApplicationPage /></AuthValidation></LoaderSuspense>} />

            <Route path="profile" element={<LoaderSuspense><AuthValidation><ProfilePage /></AuthValidation></LoaderSuspense>} />

            {/* Unauthed routes */}

            <Route path="faq" element={<LoaderSuspense><FaqView /></LoaderSuspense>} />
            <Route path="articles" element={<LoaderSuspense><Articles /></LoaderSuspense>} />
            <Route path="sanas-bodies" element={<LoaderSuspense><SanasBodyList /></LoaderSuspense>} />
            <Route path="enquiry" element={<LoaderSuspense><EnquiryPage /></LoaderSuspense>} />
            <Route path='bulk-upload' element={<LoaderSuspense><AuthValidation><BuildingBulkUpload /></AuthValidation></LoaderSuspense>} />
          </Route>

          <Route
            element={
              <HomePageContainer noPadding>
                <Suspense><ChatPopup /></Suspense>
                <Outlet />
              </HomePageContainer>
            }>
            <Route path="home" element={<LoaderSuspense><HomePage /></LoaderSuspense>} />
            <Route path="about-us" element={<LoaderSuspense><AboutUsPage /></LoaderSuspense>} />
            <Route path="info" element={<LoaderSuspense><InfoPage /></LoaderSuspense>} />
            <Route path="legislation" element={<LoaderSuspense><LegislationPage /></LoaderSuspense>} />
            <Route path="gallery" element={<LoaderSuspense><GalleryPage /></LoaderSuspense>} />
            <Route path="team" element={<LoaderSuspense><TeamPage /></LoaderSuspense>} />
            <Route path="faq" element={<LoaderSuspense><FaqView /></LoaderSuspense>} />
            <Route path="articles" element={<LoaderSuspense><Articles /></LoaderSuspense>} />
            <Route path="sanas-bodies" element={<LoaderSuspense><SanasBodyList /></LoaderSuspense>} />
            <Route path="enquiry" element={<LoaderSuspense><EnquiryPage /></LoaderSuspense>} />

            <Route path="question-1" element={<LoaderSuspense><QuestionOnePage /></LoaderSuspense>} />
            <Route path="question-2" element={<LoaderSuspense><QuestionTwoPage /></LoaderSuspense>} />
            <Route path="question-3" element={<LoaderSuspense><QuestionThreePage /></LoaderSuspense>} />
            <Route path="question-4" element={<LoaderSuspense><QuestionFourPage /></LoaderSuspense>} />
          </Route>

          <Route path="/login" element={<LoaderSuspense><LoginPage /></LoaderSuspense>} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/register" element={<LoaderSuspense><RegisterPage /></LoaderSuspense>} />
          <Route path="/register-as-professional" element={<LoaderSuspense><RegisterPage isRegisteredProfessional /></LoaderSuspense>} />
          <Route path="/request-reset" element={<LoaderSuspense><RequestPasswordResetPage /></LoaderSuspense>} />
          <Route path="/reset-password/:resetToken" element={<LoaderSuspense><ResetPasswordPage /></LoaderSuspense>} />
          <Route path='/authorize/:authToken' element={<LoaderSuspense><AuthorizePage onAuth={authValue[1]} /></LoaderSuspense>} />
          <Route path='/verify-email/:token' element={<LoaderSuspense><VerifyEmailPage /></LoaderSuspense>} />
          <Route path='/404' element={<LoaderSuspense><Error404 /></LoaderSuspense>} />
          <Route path='*' element={<LoaderSuspense><Error404 /></LoaderSuspense>} />
        </Routes>
      </SnackbarProvider>
    </AuthContext.Provider>
  );
}

export default withQueryClientProvider(App);
